import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import { AiOutlineDownload } from 'react-icons/ai';
// import { TiArrowUnsorted } from 'react-icons/ti';
import * as _ from 'lodash';
import { roundOfDigit, startsWith } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { BOOKING_SUMMARY_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
// import { FETCH_CITY, FETCH_STATE } from 'actions/address';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
// import { FETCH_CHARGING_STATION } from 'actions/chargingStation';

const BookingSummaryReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [allBookingSummaryReportData, setAllBookingSummaryReportData] = useState([]);
  // const [fieldValue, setFieldValue] = useState('All');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  // const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  // const [selectedStationOptions, setSelectedStationOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).tz('Asia/Dubai').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').endOf('day'));

  const bookingSummaryReportList = useSelector((state) => state.bookingSummaryReport.bookingSummaryReports);
  // const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  // const allCity = useSelector((state) => state.city.city);
  // const allState = useSelector((state) => state.state.states);

  // const stationFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allChargingStations, (station) => {
  //     return { label: station.name, value: station.id };
  //   }),
  // ];

  // const stateFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allState, (state) => {
  //     return { label: state.name, value: state.name };
  //   }),
  // ];

  // const cityFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allCity, (city) => {
  //     return { label: city.name, value: city.name };
  //   }),
  // ];

  const bookingSummaryReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({ type: BOOKING_SUMMARY_REPORT, payload: data });
    },
    [startDate, endDate]
  );

  useEffect(() => {
    bookingSummaryReport();
  }, []);

  // useEffect(()=>{
  //   const data = {
  //     state: _.map(selectedStateOptions, (item) => item.value),
  //     city: _.map(selectedCityOptions, (item) => item.value),
  //     station: _.map(selectedStationOptions, (item) => item.value),
  //   }
  //     bookingSummaryReport(data);
  // },[selectedStateOptions,selectedCityOptions,selectedStationOptions])

  // useEffect(() => {
  //   dispatch({ type: FETCH_STATE, payload: {} });
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: {} });
  // }, []);

  // const getCityByState = useCallback((stateName = '') => {
  //   dispatch({ type: FETCH_CITY, payload: { state_name: stateName } });
  // }, []);

  // function getDropdownButtonLabel({ placeholderButtonLabel }) {
  //     return `${placeholderButtonLabel}`;
  // }

  // function stateOnChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //     getCityByState(_.get(event, 'option.value'));
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   //   getCityByState(_.get(event, 'option.value'));
  //   // }
  //   else {
  //     this.setState(value);
  //     getCityByState(_.get(event, 'option.value'));
  //   }
  // }

  // function onChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   // }
  //   else {
  //     this.setState(value);
  //   }
  // }

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      bookingSummaryReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && bookingSummaryReport(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && bookingSummaryReport(oldestData);
  //   fieldValue === 'All' && bookingSummaryReport();
  // }, [fieldValue]);

  useEffect(() => {
    setAllBookingSummaryReportData(
      _.filter(
        bookingSummaryReportList,
        (item) =>
          startsWith(_.get(item, 'name'), searchText) ||
          startsWith(_.get(item, 'state'), searchText) ||
          startsWith(_.get(item, 'city'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'charging_scheduled_amount'), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'charging_cancelled_amount'), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'charging_completed_amount', ''), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'charging_returned_amount', ''), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'charging_tax_amount', ''), 2).toString(), searchText)
      )
    );
  }, [searchText, bookingSummaryReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      bookingSummaryReport(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      bookingSummaryReport(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Booking Summary Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').startOf('day')}
                          initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn">
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block">
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                    {/* <Col xl={1} md={2}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stateFilter}
                        placeholderButtonLabel="Select state"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStateOptions}
                        onChange={stateOnChange}
                        setState={setSelectedStateOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={cityFilter}
                        placeholderButtonLabel="Select city"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedCityOptions}
                        onChange={onChange}
                        setState={setSelectedCityOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stationFilter}
                        placeholderButtonLabel="Select Station"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStationOptions}
                        onChange={onChange}
                        setState={setSelectedStationOptions}
                        hideSearch={true}
                      />
                    </Col>
                  </Row> */}
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Station Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>State Name</span>
                              <span className="ico" onClick={() => handleSorting('state', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>City Name</span>
                              <span className="ico" onClick={() => handleSorting('city', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booked</span>
                              <span className="ico" onClick={() => handleSorting('charging_scheduled', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking Amount (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_scheduled_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Cancelled Booking (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_cancelled', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Cancelled Booking Amount (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_cancelled_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Completed</span>
                              <span className="ico" onClick={() => handleSorting('charging_completed', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Actual Amount (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_completed_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Return Amount (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_returned_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Tax Amount (INR)</span>
                              <span className="ico" onClick={() => handleSorting('charging_tax_amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allBookingSummaryReportData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No Booking Summary Report Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allBookingSummaryReportData, (item, key) => {
                            return (
                              <>
                                <tr key={`booking-summary-report-${key}`}>
                                  <td>{key + 1}</td>
                                  <td>{_.get(item, 'name', '')}</td>
                                  <td>{_.get(item, 'state', '')}</td>
                                  <td>{_.get(item, 'city', '')}</td>
                                  <td>{_.get(item, 'charging_scheduled', '')}</td>
                                  <td>{roundOfDigit(_.get(item, 'charging_scheduled_amount', ''), 2)}</td>
                                  <td>{_.get(item, 'charging_cancelled', '')}</td>
                                  <td>{roundOfDigit(_.get(item, 'charging_cancelled_amount', ''), 2)}</td>
                                  <td>{_.get(item, 'charging_completed', '')}</td>
                                  <td>{roundOfDigit(_.get(item, 'charging_completed_amount', ''), 2)}</td>
                                  <td>{roundOfDigit(_.get(item, 'charging_returned_amount', ''), 2)}</td>
                                  <td>{roundOfDigit(_.get(item, 'charging_tax_amount', ''), 2)}</td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default BookingSummaryReport;
