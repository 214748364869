import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_WALLET_TRANSACTION_REPORT, WALLET_TRANSACTION_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { roundOfDigit, startsWith } from 'components/common/utils';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';

const WalletTransactionReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [allWalletTransactionReportData, setAllWalletTransactionReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).tz('Asia/Dubai').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  const [changeWalletType, setChangeWalletType] = useState(false);
  const [changePaymentType, setChangePaymentType] = useState(false);

  const walletTransactionReportList = useSelector((state) => state.walletTransactionReport.walletTransactionReports);

  const walletTransactionReport = useCallback(
    (value) => {
      const data = {
        ...value,
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({ type: WALLET_TRANSACTION_REPORT, payload: data });
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      walletTransactionReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    setAllWalletTransactionReportData(
      _.filter(
        walletTransactionReportList,
        (item) =>
          startsWith(_.get(item, 'user.name'), searchText) ||
          startsWith(_.get(item, 'type'), searchText) ||
          startsWith(_.get(item, 'id'), searchText) ||
          startsWith(_.get(item, 'booking.charger.charging_station.name'), searchText) ||
          startsWith(_.get(item, 'booking.id'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'current_balance'), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'amount'), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount'), 2).toString(), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount'), 2).toString(), searchText) ||
          startsWith(_.get(item, 'wallet_type'), searchText) ||
          startsWith(moment(_.get(item, 'createdAt')).tz('Asia/Dubai').format('DD/MM/YYYY  H:mm:ss'), searchText) ||
          startsWith(_.get(item, 'booking.charger.charger_id'), searchText)
      )
    );
  }, [searchText, walletTransactionReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };
  useEffect(() => {
    walletTransactionReport();
  }, []);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      walletTransactionReport(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      walletTransactionReport(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // Download Excel Functionality

  const downloadWalletTransactionReport = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '', wallet_type: '', type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '', wallet_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '', type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.type === 'All') {
        const filterData = { ...advanceFilterData, type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.wallet_type === 'All') {
        const filterData = { ...advanceFilterData, wallet_type: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
          to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        };
        dispatch({
          type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      dispatch({
        type: DOWNLOAD_WALLET_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'Wallet Transaction Report'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadWalletTransactionReport();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '' };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllStation();
  }, []);

  const allWalletType = [
    { label: 'Book', value: 'Book' },
    { label: 'Booking Cancellation', value: 'bookingCancellation' },
    { label: 'CMS Credit', value: 'cMSCredit' },
    { label: 'Refund', value: 'Refund' },
    { label: 'Online Credit', value: 'onlineCredit' },
    { label: 'Auto Cancellation', value: 'autoCancellation' },
  ];

  const allPaymentType = [
    { label: 'Debit', value: 'debit' },
    { label: 'Credit', value: 'credit' },
  ];

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Wallet Transaction Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').startOf('day')}
                          initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block" onClick={downloadFile}>
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Wallet ID</span>
                              <span className="ico" onClick={() => handleSorting('id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>User Name</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              {/*<span className="ico" onClick={() => handleSorting('user.name', sortByItem)}>*/}
                              {/*  <BiSort size={15} />*/}
                              {/*</span>*/}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Mobile No.</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              {/*<span className="ico" onClick={() => handleSorting('user.name', sortByItem)}>*/}
                              {/*  <BiSort size={15} />*/}
                              {/*</span>*/}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Station Name</span>
                              {/*<span className="ico" onClick={() => handleSorting('name', sortByItem)}>*/}
                              {/*  <BiSort size={15} />*/}
                              {/*</span>*/}
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charger ID (Connector)</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Booking Id</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Payment Type</span>
                              <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Wallet Amount</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                              <span className="ico" onClick={() => handleSorting('current_balance', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Transaction Amount</span>
                              <span className="ico" onClick={() => handleSorting('amount', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Balance Amount</span>
                              <span className="ico" onClick={() => handleSorting('current_balance', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Wallet Type</span>
                              <span className="ico" onClick={() => handleSorting('wallet_type', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Referal Name</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Transaction Date</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allWalletTransactionReportData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No Wallet Transaction Report Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allWalletTransactionReportData, (item, key) => {
                            const connectorData = _.find(item && item.booking ? _.get(item, 'booking.charger.plugs', '') : '', {
                              connector_id: item && item.booking ? item.booking.connectorId : '',
                            });
                            return (
                              <>
                                <tr key={`wallet-transaction-report-${key}`}>
                                  <td>{key + 1}</td>
                                  <td>{_.get(item, 'id', '')}</td>
                                  <td>{_.get(item, 'user.name', 'Guest User')}</td>
                                  <td>{_.get(item, 'user.phone', '')}</td>
                                  <td>{_.get(item, 'booking.charger.charging_station.name', '')}</td>
                                  <td>
                                    {_.get(item, 'booking.charger.charger_id', '')} ({_.get(connectorData, 'name', '')})
                                  </td>
                                  <td>{_.get(item, 'booking.id', '')}</td>
                                  <td>{_.get(item, 'type', '')}</td>
                                  <td>{roundOfDigit(_.get(item, 'current_balance', ''), 2)}</td>
                                  <td>{roundOfDigit(_.get(item, 'amount', ''), 2)}</td>
                                  <td>
                                    {' '}
                                    {_.get(item, 'type', '') === 'credit'
                                      ? roundOfDigit(_.get(item, 'current_balance', 0) + _.get(item, 'amount', ''), 2)
                                      : roundOfDigit(_.get(item, 'current_balance', 0) - _.get(item, 'amount', ''), 2)}
                                  </td>
                                  <td>{_.get(item, 'wallet_type', '')}</td>
                                  <td>{item.createdAt ? moment(item.createdAt).tz('Asia/Dubai').format('DD/MM/YYYY  H:mm:ss') : ''}</td>
                                </tr>
                              </>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.wallet_type && delete values['wallet_type'];
              !values.type && delete values['type'];
              if (values.charging_station === 'All' && values.wallet_type === 'All' && values.type === 'All') {
                const walletTransactionReportData = { ...values, charging_station: '', wallet_type: '', type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All' && values.wallet_type === 'All') {
                const walletTransactionReportData = { ...values, charging_station: '', wallet_type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All' && values.type === 'All') {
                const walletTransactionReportData = { ...values, charging_station: '', type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.wallet_type === 'All' && values.type === 'All') {
                const walletTransactionReportData = { ...values, wallet_type: '', type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.charging_station === 'All') {
                const walletTransactionReportData = { ...values, charging_station: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.wallet_type === 'All') {
                const walletTransactionReportData = { ...values, wallet_type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else if (values.type === 'All') {
                const walletTransactionReportData = { ...values, type: '' };
                walletTransactionReport(walletTransactionReportData);
              } else {
                walletTransactionReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Station"
                      options={
                        !changeStation
                          ? !_.isEmpty(allStation) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                    <Select
                      label="Wallet Type"
                      options={
                        !changeWalletType
                          ? !_.isEmpty(allWalletType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allWalletType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allWalletType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Wallet Type"
                      name="wallet_type"
                      isMulti
                      value={values.wallet_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeWalletType(true);
                          setFieldValue(`wallet_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`wallet_type`, '');
                          setChangeWalletType(false);
                        } else {
                          setChangeWalletType(false);
                          setFieldValue('wallet_type', val);
                        }
                      }}
                    />
                    <Select
                      label="Payment Type"
                      options={
                        !changePaymentType
                          ? !_.isEmpty(allPaymentType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allPaymentType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allPaymentType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Payment Type"
                      name="type"
                      isMulti
                      value={values.type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangePaymentType(true);
                          setFieldValue(`type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`type`, '');
                          setChangePaymentType(false);
                        } else {
                          setChangePaymentType(false);
                          setFieldValue('type', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      walletTransactionReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default WalletTransactionReport;
