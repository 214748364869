import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { B2C_STATION_CHARGING_HISTORY, DOWNLOAD_B2C_STATION_CHARGING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { millisecondsToHourMinute, roundOfDigit, startsWith } from 'components/common/utils';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { IoSearchOutline } from 'react-icons/io5';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';

const B2CStationChargingHistory = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [allB2cStationChargingHistoryData, setAllB2cStationChargingHistoryData] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).tz('Asia/Dubai').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeState, setChangeState] = useState(false);
  const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);

  const b2cStationChargingHistoryList = useSelector((state) => state.b2cStationChargingHistory.b2cStationChargingHistory);

  const b2cStationChargingHistory = useCallback(
    (value) => {
      const data = {
        ...value,
        type: 'Public',
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        status: 'completed',
      };
      dispatch({ type: B2C_STATION_CHARGING_HISTORY, payload: data });
    },
    [startDate, endDate]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      b2cStationChargingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  useEffect(() => {
    setAllB2cStationChargingHistoryData(
      _.filter(
        b2cStationChargingHistoryList,
        (item) =>
          startsWith(_.get(item, 'charger.charging_station.name'), searchText) ||
          startsWith(_.get(item, 'transaction_id'), searchText) ||
          startsWith(roundOfDigit(_.get(item, 'meterstop') - _.get(item, 'meterstart'), 3).toString(), searchText) ||
          startsWith(millisecondsToHourMinute(_.get(item, 'time_taken')).toString(), searchText) ||
          startsWith(_.get(item, 'idTag').toString(), searchText) ||
          startsWith(_.get(item, 'stop_reason'), searchText) ||
          startsWith(_.get(item, 'charger.charger_id'), searchText) ||
          startsWith(_.get(item, 'vehicle.name'), searchText) ||
          startsWith(_.get(item, 'vehicle.vin_num'), searchText) ||
          startsWith(_.get(item, 'vehicle.make'), searchText) ||
          startsWith(_.get(item, 'vehicle.model'), searchText)
      )
    );
  }, [searchText, b2cStationChargingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    b2cStationChargingHistory();
  }, []);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      b2cStationChargingHistory(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      b2cStationChargingHistory(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  // Download Excel Functionality

  const downloadB2CStationChargingHistory = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.charging_station === 'All' && advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          excel: true,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          report: 'b2cstationcharginghistory',
          type: 'Public',
          status: 'completed',
        };
        dispatch({
          type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
              }
            }
          },
        });
      }
    } else {
      const data = {
        excel: true,
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        report: 'b2cstationcharginghistory',
        type: 'Public',
        status: 'completed',
      };
      dispatch({
        type: DOWNLOAD_B2C_STATION_CHARGING_HISTORY,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `${'B2C Station Charging History'}.xlsx`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData]);

  const downloadFile = () => {
    downloadB2CStationChargingHistory();
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const allCountries = useSelector((state) => state.country.countries);
  const allState = useSelector((state) => state.state.states);
  const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  const countryPage = useSelector((state) => state.country.page);
  const statePage = useSelector((state) => state.state.page);
  const cityByStatePage = useSelector((state) => state.cityByState.page);
  const countryTotalPage = useSelector((state) => state.country.totalPages);
  const stateTotalPage = useSelector((state) => state.state.totalPages);
  const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', charging_station: '' };

  const getAllCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_COUNTRY, payload: data });
  }, []);

  const getStateByCountry = useCallback((data = {}) => {
    dispatch({ type: FETCH_STATE, payload: data });
  }, []);

  const getCityByStateName = useCallback((data = {}) => {
    dispatch({ type: GET_CITY_BY_STATE, payload: data });
  }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    getAllCountry();
    getAllStation();
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="B2C Station Charging History" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').startOf('day')}
                          initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block" onClick={downloadFile}>
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Country</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>State</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>City</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>OCPP Id</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Station Name</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charger ID (Connector)</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Connector Type</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Transaction Id</span>
                              <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Date & Time</span>
                              <span className="ico" onClick={() => handleSorting('schedule_datetime', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Meter Start Reading(Wh)</span>
                              <span className="ico" onClick={() => handleSorting('meterstart', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>Meter Stop Reading(Wh)</span>
                              <span className="ico" onClick={() => handleSorting('meterstop', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Session Unit Consumption (kWh)</span>
                              <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>Session Start Date Time(hh:mm)</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>Session Stop Date Time(hh:mm)</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Session Duration (hh:mm)</span>
                              <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>StartSoC</span>
                              <span className="ico" onClick={() => handleSorting('StartSoc', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>StopSoC</span>
                              <span className="ico" onClick={() => handleSorting('StopSoc', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Reason to Stop</span>
                              <span className="ico" onClick={() => handleSorting('stop_reason', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>idTag/OTP</span>
                              <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Mobile No.</span>
                            </div>
                          </th>
                          {/* <th>
                            <div className="sorting">
                              <span>VIN/Registration No.</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>EV Make</span>
                            </div>
                          </th> */}
                          {/* <th>
                            <div className="sorting">
                              <span>EV Model</span>
                            </div>
                          </th> */}
                          <th>
                            <div className="sorting">
                              <span>Make (Model)</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allB2cStationChargingHistoryData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No B2C Station Charging History Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allB2cStationChargingHistoryData, (item, index) => {
                            const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                            const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart'))
                              ? ''
                              : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);
                            return (
                              <tr key={`b2c-station-charging-history-${index}`}>
                                <td>{index + 1}</td>
                                <td>{_.get(item, 'charger.charging_station.name', '')}</td>
                                <td>
                                  {_.get(item, 'charger.charger_id', '')} ({_.get(connectorData, 'name', '')})
                                </td>
                                <td>{_.get(item, 'transaction_id', '')}</td>
                                <td>{item.schedule_datetime ? moment(item.schedule_datetime).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss') : ''}</td>
                                <td>{unitConsumed}</td>
                                <td>{millisecondsToHourMinute(_.get(item, 'time_taken'))}</td>
                                <td>{_.get(item, 'stop_reason', '')}</td>
                                <td>{_.get(item, 'idTag', '')}</td>
                                <td>{_.get(item, 'customer_user_booked.phone', 'NA')}</td>
                                <td>
                                  {_.get(item, 'vehicle.make', '')} ({_.get(item, 'vehicle.model', '')})
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.charging_station && delete values['charging_station'];
              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '', city: '', charging_station: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All' && values.charging_station === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '', charging_station: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.city === 'All' && values.charging_station === 'All') {
                const b2cStationChargingHistoryData = { ...values, city: '', charging_station: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All' && values.city === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '', city: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.city === 'All') {
                const b2cStationChargingHistoryData = { ...values, city: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.state === 'All') {
                const b2cStationChargingHistoryData = { ...values, state: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else if (values.charging_station === 'All') {
                const b2cStationChargingHistoryData = { ...values, charging_station: '' };
                b2cStationChargingHistory(b2cStationChargingHistoryData);
              } else {
                b2cStationChargingHistory(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <Select
                    label="Country"
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder="Select Country"
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setFieldValue(`country`, val);
                      // setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label="State"
                    options={
                      !changeState
                        ? values.country &&
                          !_.isEmpty(allState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder="Select State"
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  />
                  <Select
                    label="City"
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                          !_.isEmpty(allCityByState) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allCityByState, (state) => {
                              return { label: state.name, value: state.name };
                            }),
                          ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select City"
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`city`, '');
                        setChangeCity(false);
                      } else {
                        setChangeCity(false);
                        setFieldValue('city', val);
                      }
                    }}
                  />
                  <div className="canvas-selection__block">
                    <Select
                      label="Station"
                      options={
                        !changeStation
                          ? !_.isEmpty(allStation) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue(`charging_station`, val);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      b2cStationChargingHistory();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default B2CStationChargingHistory;
