import React, { useCallback, useEffect, useState } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsLightningCharge } from 'react-icons/bs';
// import { MdOutlinePeopleAlt } from 'react-icons/md';
import { BsBarChartFill } from 'react-icons/bs';
import { IoTimeOutline } from 'react-icons/io5';
import { BsPlugin } from 'react-icons/bs';
import PropTypes from 'prop-types';
import Avatar from 'components/inputs/Avatar';
// import DummyImage from 'assets/images/icons/dummyImage.png';
import { /*GET_DASHBOARD_DATA,*/ GET_DASHBOARD_WIDGET_DATA, GET_DASHBOARD_CHART_DATA, GET_DASHBOARD_PIECHART_DATA } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
// import { SUPER_ADMIN, ADMIN, TENANT_ADMIN } from 'components/common/constant';
// import { AiFillCar } from 'react-icons/ai';
// import moment from 'moment';
// import { useHistory } from 'react-router-dom';
import { roundOfDigit } from 'components/common/utils';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  // const dashboardList = useSelector((state) => state.dashboard.dashboard);

  const isLoading = useSelector((state) => state.dashboardWidget.isLoading);
  const dashboardWidgetList = useSelector((state) => state.dashboardWidget.dashboardWidgets);
  const isLoader = useSelector((state) => state.dashboardChart.isLoading);
  const dashboardChartData = useSelector((state) => state.dashboardChart.dashboardChart);
  const isLoad = useSelector((state) => state.dashboardPieChart.isLoading);
  const dashboardPieChartData = useSelector((state) => state.dashboardPieChart.dashboardPiechart);
  // const profileData = useSelector((state) => state.profile.userProfile);
  // const currentUserRole = _.get(profileData, 'role', '');
  // const saasClientTotal = _.get(
  //   _.find(_.get(dashboardList, 'saasClients', []), (item) => _.has(item, 'total')),
  //   'total',
  //   0
  // );

  const [currentScheduleOfBookingCount, setCurrentScheduleOfBookingCount] = useState('Weekly');
  const [currentScheduleOfEnergySold, setCurrentScheduleOfEnergySold] = useState('Weekly');

  // const getDashboardData = useCallback(() => {
  //   dispatch({ type: GET_DASHBOARD_DATA });
  // }, []);

  const getDashboardWidgetData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_WIDGET_DATA });
  }, []);

  const getDashboardChartData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_CHART_DATA });
  }, []);

  const getDashboardPiechartData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_PIECHART_DATA });
  }, []);

  useEffect(() => {
    // getDashboardData();
    getDashboardWidgetData();
    getDashboardChartData();
    getDashboardPiechartData();
  }, []);

  const colorMap = {
    Available: '#14AC7F',
    Unavailable: '#FF8B00',
    'In Use': '#C9A11B',
    Preparing: '#ffffff',
    SuspendedEVSE: 'rgba(153, 102, 255, 0.6)',
    SuspendedEV: 'rgb(230, 169, 236)',
    Finishing: '#1e3249',
    Reserved: 'rgb(230, 246, 157)',
    Faulted: '#be210b',
    Charging: '#3C7CDD',
  };

  const filteredEntries = Object.entries(dashboardPieChartData).filter(([, value]) => value !== 0);

  const labels = filteredEntries.map(([key]) => key);
  const data = filteredEntries.map(([, value]) => value);
  const backgroundColors = labels.map((label) => colorMap[label]);

  const chargePointStatus = {
    datasets: [
      {
        label: 'ChargePoint Status',
        data: data,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 1,
      },
    ],
    labels: labels,
  };

  const energySold =
    currentScheduleOfEnergySold === 'Weekly'
      ? {
          labels: _.map(dashboardChartData.weeklyUnitConsumted, (item) => item.weekDay).reverse(),
          datasets: [
            {
              label: 'Energy Sold',
              data: _.map(dashboardChartData.weeklyUnitConsumted, (item) => item.total / 1000).reverse(),
              borderColor: '#FED035',
              tension: 0.4,
            },
          ],
        }
      : {
          labels: _.map(dashboardChartData.monthlyUnitConsumted, (item) => item.month),
          datasets: [
            {
              label: 'Energy Sold',
              data: _.map(dashboardChartData.monthlyUnitConsumted, (item) => item.total / 1000),
              borderColor: '#f1cf78',
              tension: 0.4,
            },
          ],
        };

  const chargerBookingCount =
    currentScheduleOfBookingCount === 'Weekly'
      ? {
          labels: _.map(dashboardChartData.weeklySession, (item) => item.weekDay).reverse(),
          datasets: [
            {
              label: 'Weekly Session Count',
              data: _.map(dashboardChartData.weeklySession, (item) => item.count).reverse(),
              backgroundColor: '#8548B4',
              barThickness: 7,
              maxBarThickness: 8,
            },
          ],
        }
      : {
          labels: _.map(dashboardChartData.monthlySession, (item) => item.month),
          datasets: [
            {
              label: 'Monthly Session Count',
              data: _.map(dashboardChartData.monthlySession, (item) => item.count),
              backgroundColor: '#8548B4',
              barThickness: 7,
            },
          ],
        };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Dashboard" />
        <div className="page-content-wrapper scrollable">
          <div className="status-blocks">
            <div className="status-block" style={{ cursor: 'default' }} title="Total Number of Sessions">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                <BsLightningCharge color="#3C7CDD" size={35} className="icon--color" />
              </div>
              <div className="status-info">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <h1>{_.get(dashboardWidgetList, 'todaySessions', 0)}</h1>
                )}
                <p>{'Sessions Today'}</p>
              </div>
            </div>
            <div className="status-block" style={{ cursor: 'default' }} title="Number of Units Consumed">
              <div className="breating-area" style={{ background: '#be210b21' }}>
                <BsLightningCharge color="#BE210B" size={35} className="icon-inner-color" />
              </div>
              <div className="status-info">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <h1>{roundOfDigit(_.get(dashboardWidgetList, 'todayUnitConsumed', 0), 2)}</h1>
                )}
                <p>{'Unit Consumed (kWh)'}</p>
              </div>
            </div>
            <div className="status-block" style={{ cursor: 'default' }} title="Average Unit Consumed Per Session">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                <BsBarChartFill color="#3C7CDD" size={35} className="icon--color" />
              </div>
              <div className="status-info">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <h1>{roundOfDigit(_.get(dashboardWidgetList, 'AverageUnitsPerSession', 0), 2)}</h1>
                )}
                <p>{'Average Consumption'}</p>
              </div>
            </div>
            <div className="status-block" style={{ cursor: 'default' }} title="Average of Duration Per Sessions">
              <div className="breating-area" style={{ background: '#be210b21' }}>
                <IoTimeOutline color="#BE210B" size={35} className="icon-inner-color" />
              </div>
              <div className="status-info">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <h1>{`${roundOfDigit(_.get(dashboardWidgetList, 'AverageDurationOfSession', 0))}m`}</h1>
                )}
                <p>{'Average Duration'}</p>
              </div>
            </div>
            <div className="status-block" style={{ cursor: 'default' }} title="Number of Charge Points">
              <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                <BsPlugin color="#3C7CDD" size={35} className="icon--color" />
              </div>
              <div className="status-info">
                {isLoading ? (
                  <div className="loader--block">
                    <Spinner as="span" animation="border" size="sm" role="status" />
                  </div>
                ) : (
                  <h1>{_.get(dashboardWidgetList, 'totalChargerPoints', 0)}</h1>
                )}
                <p>{'No. of Charge Points'}</p>
              </div>
            </div>
          </div>
          <div className="dashboard-details__main">
            <div className="dashboard-details--inner">
              <div className="dashboard-table__main">
                <Row>
                  <Col xl={/*currentUserRole === ADMIN ? 12 :*/ 8} lg={12} md={12}>
                    <Row>
                      {/* {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN || currentUserRole === ADMIN) && ( */}
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-energySold_chart_main">
                              <div className="dashboard-energySold_chart_inner">
                                <div className="dashboard-energySold_chart_block">
                                  <div className="dashboard-energySold_name">Energy Sold (kWh)</div>
                                  {/*<div className="dashboard-energySold__btn">*/}
                                  {/*  <Button className="dashboard-energySold-inner-btn">Today : 1325 kWh</Button>*/}
                                  {/*</div>*/}
                                </div>
                                <div className="dashboard-energySold_chart_content">
                                  <span
                                    className={`dashboard-energySold__days ${currentScheduleOfEnergySold === 'Weekly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfEnergySold('Weekly')}
                                  >
                                    Weekly
                                  </span>
                                  <span
                                    className={`dashboard-energySold_days ${currentScheduleOfEnergySold === 'Monthly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfEnergySold('Monthly')}
                                  >
                                    Monthly
                                  </span>
                                  <span className="dashboard-energySold_line" />
                                  {/*<div className="dashboard-energySold_filter_icon">*/}
                                  {/*  <RiFilter3Fill />*/}
                                  {/*  <span className="dashboard-energySold_filter_name">Filters</span>*/}
                                  {/*</div>*/}
                                </div>
                              </div>
                              <div className="dashboard-energySold_chart-img">
                                {isLoader ? (
                                  <div className="loader--block">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                  </div>
                                ) : (
                                  <div className="energySold_chart-img">
                                    <Line data={energySold} options={options} height="110px" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      {/* )} */}
                      {/* {(currentUserRole === TENANT_ADMIN || currentUserRole === SUPER_ADMIN) && ( */}
                        <Col md={12}>
                          <Card>
                            <div className="dashboard-bookingCount_chart_main">
                              <div className="dashboard-bookingCount_chart_inner">
                                <div className="dashboard-bookingCount_chart_block">
                                  <div className="dashboard-bookingCount_name">Session Count</div>
                                </div>
                                <div className="dashboard-bookingCount_chart_content">
                                  <span
                                    className={`dashboard-bookingCount__days ${currentScheduleOfBookingCount === 'Weekly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfBookingCount('Weekly')}
                                  >
                                    Weekly
                                  </span>
                                  <span
                                    className={`dashboard-bookingCount_days ${currentScheduleOfBookingCount === 'Monthly' ? 'active' : ''}`}
                                    onClick={() => setCurrentScheduleOfBookingCount('Monthly')}
                                  >
                                    Monthly
                                  </span>
                                </div>
                              </div>
                              <div className="dashboard-bookingCount_chart-img">
                                {isLoader ? (
                                  <div className="loader--block">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                  </div>
                                ) : (
                                  <div className="bookingCount_chart-img">
                                    <Bar data={chargerBookingCount} options={options} height="110px" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      {/* )} */}
                    </Row>
                  </Col>
                  <Col xl={4} lg={12} md={12}>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <div className="dashboard-chart_main">
                            <div className="dashboard-chart_inner">
                              <div className="dashboard-chart_name">ChargePoint Status</div>
                              <div className="dashboard-chart-img_block">
                                {isLoad ? (
                                  <div className="loader--block">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                  </div>
                                ) : (
                                  <div className="dashboard-chart_img">
                                    <Doughnut data={chargePointStatus} options={options} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                      {/* {currentUserRole === SUPER_ADMIN && (
                        <Col md={12}>
                          <Card>
                            <div className="dashboard__sassClient__main">
                              <div className="dashboard__saasClient_card">
                                <DashboardCard
                                  title={saasClientTotal}
                                  icon={<MdOutlinePeopleAlt color="#3C7CDD" className="icon--color" />}
                                  subTitle={'Total Saas Client'}
                                />
                              </div>
                              <div className="dashboard_inner_saasClient">
                                <div className="dashboard__top_name">Top 5</div>
                                {_.map(dashboardList.topFiveUsers, (data, index) => {
                                  return (
                                    <div key={index}>
                                      {_.map(data.user, (item) => {
                                        return (
                                          <div key={index}>
                                            <SaasClient
                                              imgSrc={_.get(item, 'picture', DummyImage)}
                                              text={_.get(item, 'name', '')}
                                              number={index + 1}
                                            />
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      )} */}
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

Dashboard.propTypes = {};
export default Dashboard;

//card Component
export const DashboardCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <div className="dashboard-card">
      <div className="dashboard-detail-box">
        <div className="dashboard-icon">
          {icon && <div className="session-icon">{icon}</div>}
          {metaIcon && <div className="session-meta-icon">{metaIcon}</div>}
        </div>
        <div className="dashboard__info">
          <div className="title-session">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  /** card icon **/
  icon: PropTypes.node,
  metaIcon: PropTypes.node,
  /** Dashboard title **/
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  /** Dashboard sub-title **/
  subTitle: PropTypes.string,
};

//realTime Event table component
export const TableItem = (props) => {
  let { icon, text, date } = props;

  return (
    <div className="realTime-event-item__main">
      <div className="realTime-event-item--inner">
        <div className="realTime-event-icon--block">
          <div className="realTime-event_icon__inner">
            <div className="realTime-event---icon">{icon}</div>
          </div>
        </div>
        <div className="realTime-event-content__main-block">
          <div className="realTime-event-content--inner">
            <div className="realTime-event-details--content-block">
              <span className="realTime-event-timing">{date}</span>
              <div className="realTime-event-status--icon">{icon}</div>
              <div className="realTime-event-text">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableItem.propTypes = {
  /** tableList dot icon */
  icon: PropTypes.object,
  /** tableList date */
  date: PropTypes.string,
  /** tableList text */
  text: PropTypes.node,
};

//saas client component
export const SaasClient = (props) => {
  let { icon, text, number } = props;
  return (
    <div className="saasClient--item__main">
      <div className="saasClient--item__inner">
        <Avatar className="saasClient_img" />
        <div className="saasClient-text">{text}</div>
      </div>
      <div className="saasClient-content__main-block">
        <div className="saasClient---number">{number}</div>
        <div className="saasClient-details-icon">{icon}</div>
      </div>
    </div>
  );
};
SaasClient.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  number: PropTypes.number,
};

//recentTable component
const RecentTable = (props) => {
  let { icon, text, date, subText, time } = props;
  return (
    <div className="recentTable--item__main">
      <div className="recentTable--item__inner">
        <div className="recentTable--item__inner_block">
          <div className="recentTable--item__img">
            <Avatar className="recentTable_img" />
          </div>
          <div className="recentTable--item_content">
            <div className="recentTable-text">{text}</div>
            <div className="recentTable--item__inner_content">
              <div className="recentTable-details-icon">{icon}</div>
              <span>{subText}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="recentTable-content__block">
        <div className="recentTable---date">{date}</div>
        <div className="recentTable---time">{time}</div>
      </div>
    </div>
  );
};
RecentTable.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  subText: PropTypes.node,
  date: PropTypes.string,
  time: PropTypes.string,
};
