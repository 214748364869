import LayoutContainer from 'components/layout/LayoutContainer';
import React, { useState, useEffect, useCallback } from 'react';
import AdminHeader from 'components/header';
import { Col, Row, Modal, Spinner } from 'react-bootstrap';
import { AiOutlineCar } from 'react-icons/ai';
import Card from 'components/inputs/Card';
import { BsSearch, BsThreeDots } from 'react-icons/bs';
import Button from 'components/inputs/Button';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import DropDown from 'components/inputs/DropDown';
import { FETCH_VEHICLE, DOWNLOAD_EXCEL_VEHICLE } from 'actions/vehicle';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import { startsWith } from 'components/common/utils';
import { DELETE_VEHICLE } from 'actions/vehicle';
import PropTypes from 'prop-types';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import moment from 'moment';
import DeleteModal from 'components/general/DeleteModal';
import { DELETE_STATUS_CODE } from 'components/common/constant';
import { BiSort } from 'react-icons/bi';
import { BsDownload } from 'react-icons/bs';
import fileDownload from 'js-file-download';
import { FETCH_USER } from 'actions/user';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';

const VehicleList = () => {
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [currentTab, setCurrentTab] = useState('totalVehicles');
  const [allVehiclesData, setAllVehiclesData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [vehicleId, setVehicleId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [selectedOption, setSelectedOption] = useState('excel');
  const reportFormats = [
    { label: 'PDF Format', value: 'pdf' },
    { label: 'Excel Sheet', value: 'excel' },
  ];

  const dispatch = useDispatch();
  const isLoader = useSelector((state) => state.vehicle.isLoading);
  const allVehicles = useSelector((state) => state.vehicle.vehicles);
  const page = useSelector((state) => state.vehicle.page);
  const totalData = useSelector((state) => state.vehicle.total);
  const limit = useSelector((state) => state.vehicle.limit);
  const totalPages = useSelector((state) => state.vehicle.totalPages);
  const role = useSelector((state) => state.profile.userProfile.role);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const hasData = allVehicles && allVehicles.length > 0;

  const getVehicle = useCallback((data) => {
    dispatch({ type: FETCH_VEHICLE, payload: data ? data : {} });
  }, []);

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setVehicleId('');
  };

  const deleteVehicle = useCallback(
    () =>
      dispatch({
        type: DELETE_VEHICLE,
        payload: vehicleId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getVehicle({ page });
          }
        },
      }),
    [vehicleId]
  );

  const downloadExcelVehicle = useCallback(() => {
    const data = {
      // excel: true,
      reportFormat: selectedOption,
    };
    dispatch({
      type: DOWNLOAD_EXCEL_VEHICLE,
      payload: data,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `Vehicle Data.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
          }
        }
      },
    });
  }, [selectedOption]);

  const downloadFile = () => {
    downloadExcelVehicle();
  };

  useEffect(() => {
    setAllVehiclesData(
      _.filter(
        allVehicles,
        (item) =>
          startsWith(_.get(item, 'make', ''), searchText) ||
          startsWith(_.get(item, 'model', ''), searchText) ||
          startsWith(_.get(item, 'RFID', ''), searchText) ||
          startsWith(moment(_.get(item, 'insurance_expiry')).format('ddd, ll'), searchText)
      )
    );
  }, [searchText, allVehicles]);

  useEffect(() => {
    getVehicle();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getVehicle(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getVehicle(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getVehicle(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const allUsers = useSelector((state) => state.user.users);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { company: '' };

  const getAllUsers = useCallback((data) => {
    dispatch({
      type: FETCH_USER,
      payload: data ? data : null,
    });
  }, []);

  useEffect(() => {
    getAllUsers({ role: 'doco' });
  }, []);

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${role === 'doco' ? 'Vehicle Management' : 'Manage Partner Vehicles'}`} />
        <div className="page-content-wrapper scrollable">
          <div className="vehicle-management-page_main">
            <div className="vehicle-management-card-block">
              {/* <Row>
                <Col xl={3}>
                  <div
                    className={`vehicle-card-block ${currentTab === 'totalVehicles' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('totalVehicles')}
                  >
                    <VehicleCard key="total-vehicles" icon={<AiOutlineCar />} title={totalData} subTitle="Total Vehicles" />
                  </div>
                </Col>
                <Col xl={3}>
                  <div
                    className={`vehicle-card-block ${currentTab === 'chargingVehicles' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('chargingVehicles')}
                  >
                    <VehicleCard
                      key="charging-vehicles"
                      icon={<AiOutlineCar />}
                      metaIcon={<BsLightningChargeFill color="#FED035" />}
                      title={0}
                      subTitle="Charging Vehicles"
                    />
                  </div>
                </Col>
                <Col xl={3}>
                  <div
                    className={`vehicle-card-block ${currentTab === 'finishedCharging' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('finishedCharging')}
                  >
                    <VehicleCard
                      key="finished-charging"
                      icon={<AiOutlineCar />}
                      metaIcon={<AiOutlineCheck color="#14AC7F" size={22} />}
                      title={0}
                      subTitle="Finished Charging"
                    />
                  </div>
                </Col>
                <Col xl={3}>
                  <div
                    className={`vehicle-card-block ${currentTab === 'vehiclesProblems' ? 'active' : ''}`}
                    onClick={() => setCurrentTab('vehiclesProblems')}
                  >
                    <VehicleCard
                      key="vehicles-with-problem"
                      icon={<AiOutlineCar />}
                      metaIcon={<AiOutlineExclamation color="#E12F17" />}
                      title={0}
                      subTitle="Vehicles with problems"
                    />
                  </div>
                </Col>
              </Row> */}
            </div>
            <div className="vehicle-list-main-block">
              <Card>
                {currentTab === 'totalVehicles' && (
                  <div className="vehicle-list-block">
                    <div className="vehicle-search-box">
                      <Row>
                        <Col xl={2} md={2}>
                          <div
                            className={`vehicle-card-block ${currentTab === 'totalVehicles' ? 'active' : ''}`}
                            onClick={() => setCurrentTab('totalVehicles')}
                          >
                            <VehicleCard key="total-vehicles" icon={<AiOutlineCar />} title={totalData} subTitle="Total Vehicles" />
                          </div>
                        </Col>
                        <Col xl={6} md={'auto'}>
                          <SearchBox preIcon={<BsSearch />} value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                        </Col>
                        {role === 'doco' ? (
                          <Col xl={'auto'} md={'auto'}>
                            <Button className="add-vehicle-btn" onClick={() => history.push('/addVehicle')}>
                              Add Vehicle
                            </Button>
                          </Col>
                        ) : (
                          <Col xl={'auto'} md={'auto'}>
                            <Button className="add-vehicle-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                              Advance Filter
                            </Button>
                          </Col>
                        )}
                        <Col xl={2} md={'auto'}>
                          <Select
                            options={reportFormats}
                            value={selectedOption}
                            onChange={(value) => setSelectedOption(value)}
                            placeholder="Select Report Format"
                          />
                        </Col>
                        <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={hasData ? downloadFile : null}>
                          {isLoading ? (
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                            </div>
                          ) : (
                            <div className={`report-excel-icon  ${!hasData ? 'disabled' : ''}`}>
                              <BsDownload title={!hasData ? '' : 'Download'} size={28} color={!hasData ? '#d3d3d3' : '#3c7cdd'} />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="vehicle-list-table">
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>Sr no.</span>
                                </div>
                              </th>
                              {/* <th>
                                <div className="sorting">
                                  <span>EV Type</span>
                                  <span className="ico" onClick={() => handleSorting('type', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th> */}
                              <th>
                                <div className="sorting">
                                  <span>Company Name</span>
                                  <span className="ico" onClick={() => handleSorting('company', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Brand (Model)</span>
                                  <span className="ico" onClick={() => handleSorting('make', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Vehicle Registration No.</span>
                                  <span className="ico" onClick={() => handleSorting('rto_no', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>MAC</span>
                                  <span className="ico" onClick={() => handleSorting('mac', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Date</span>
                                  <span className="ico" onClick={() => handleSorting('make', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th>
                              {/* <th>
                                <div className="sorting">
                                  <span>RFID/ID Tag</span>
                                  <span className="ico" onClick={() => handleSorting('RFID', sortByItem)}>
                                    <BiSort size={15} />
                                  </span>
                                </div>
                              </th> */}
                              {role === 'doco' ? (
                                <th>
                                  <div>
                                    <span>Action</span>
                                  </div>
                                </th>
                              ) : null}
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            {isLoader ? (
                              <tr>
                                <td colSpan={10} className="border-0">
                                  <div className="loader--block">
                                    <Spinner as="span" animation="border" size="sm" role="status" />
                                    <span className="ps-2">Loading...</span>
                                  </div>
                                </td>
                              </tr>
                            ) : _.isEmpty(allVehiclesData) ? (
                              <tr>
                                <td colSpan={8} className="border-0">
                                  <div className="empty-data-block">No vehicles</div>
                                </td>
                              </tr>
                            ) : (
                              _.map(_.filter(allVehiclesData), (vehicle, key) => {
                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <tr key={`vehicle-data-item-${serial_num}`}>
                                    <td>{serial_num + 1}</td>
                                    <td>{_.get(vehicle, 'owner.company', '-')}</td>
                                    {/* <td>{_.get(vehicle, 'type', '')}</td> */}
                                    <td>
                                      {_.get(vehicle, 'make', '')} ({_.get(vehicle, 'model', '')})
                                    </td>
                                    <td>{_.get(vehicle, 'vin_num', '-')}</td>
                                    <td>{_.get(vehicle, 'mac', '-')}</td>
                                    <td>{_.get(vehicle, 'createdAt') ? moment(_.get(vehicle, 'createdAt', '-')).format('DD/MM/YYYY') : '-'}</td>
                                    {/* <td>{_.get(vehicle, 'RFID', '')}</td> */}
                                    {role === 'doco' ? (
                                      <td>
                                        <div key={`action-${key}`} className="vehicle-list">
                                          <span className="list-icon">
                                            <DropDown
                                              popupClass="bottom-left"
                                              trigger={
                                                <div className={'more-info'}>
                                                  <BsThreeDots />
                                                </div>
                                              }
                                            >
                                              <ul>
                                                <li className="item" onClick={() => history.push(`/editVehicle/${vehicle.id}`)}>
                                                  Edit
                                                </li>
                                                <li
                                                  className="item"
                                                  onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setVehicleId(_.get(vehicle, 'id', ''));
                                                  }}
                                                >
                                                  Delete
                                                </li>
                                              </ul>
                                            </DropDown>
                                          </span>
                                        </div>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!_.isEmpty(allVehiclesData) && (
                      // <Pagination currentPage={page} limit={limit} total={totalData} onPageChange={(pageVal) => handlePageClick(pageVal)} />
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    )}
                    {showDeleteModal && (
                      <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
                        <DeleteModal title="vehicle" onClose={handleCloseDeleteModel} onRemove={deleteVehicle} />
                      </Modal>
                    )}
                  </div>
                )}
                {currentTab === 'chargingVehicles' && <div className="d-flex align-items-center justify-content-center">Pending...</div>}
                {currentTab === 'finishedCharging' && <div className="d-flex align-items-center justify-content-center">Pending...</div>}
                {currentTab === 'vehiclesProblems' && <div className="d-flex align-items-center justify-content-center">Pending...</div>}
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.company && delete values['company'];
              getVehicle(values);
              handleAdvanceFilterData(values);
              onCloseAdvanceFilterPopup();
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Company"
                      options={_.map(allUsers, (item) => {
                        return { label: item.company, value: item.id };
                      })}
                      placeholder="Select Company"
                      name="company"
                      value={values.company}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        setFieldValue(`company`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      getVehicle();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </React.Fragment>
  );
};

export default VehicleList;

export const VehicleCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <Card className="vehicle-card">
      <div className="vehicle-detail-box">
        <div className="vehicle-icon">
          <div className="car-icon">{icon}</div>
          <div className="vehicle-status-icon">{metaIcon}</div>
        </div>
        <div className="vehicle__count-info">
          <div className="total-vehicle">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </Card>
  );
};

VehicleCard.propTypes = {
  /**
   * Card icon
   */
  icon: PropTypes.node,
  /**meta icon */
  metaIcon: PropTypes.node,
  /**
   * Vehicle title
   */
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Vehical sub-title
   */
  subTitle: PropTypes.string,
};
