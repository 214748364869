import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
// import Select from 'components/inputs/Select';
// import { AiOutlineDownload } from 'react-icons/ai';
import * as _ from 'lodash';
import { B2B_STATION_CHARGING_HISTORY } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { millisecondsToHourMinute, roundOfDigit, startsWith } from 'components/common/utils';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { BsDownload } from 'react-icons/bs';
import { IoSearchOutline } from 'react-icons/io5';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
// import Select from 'components/inputs/Select';
// import { FETCH_CITY, FETCH_COUNTRY, FETCH_STATE } from 'actions/address';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
// import { FETCH_CHARGING_STATION } from 'actions/chargingStation';

const B2BStationChargingHistory = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [selectedStateOptions, setSelectedStateOptions] = useState([]);
  // const [selectedCityOptions, setSelectedCityOptions] = useState([]);
  // const [selectedStationOptions, setSelectedStationOptions] = useState([]);
  // const [fieldValue, setFieldValue] = useState('All');
  // const [field, setField] = useState('All');
  // const [countryName, setCountryName] = useState();
  const [allB2bStationChargingHistoryData, setAllB2bStationChargingHistoryData] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()).tz('Asia/Dubai').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').endOf('day'));
  const b2bStationChargingHistoryList = useSelector((state) => state.b2bStationChargingHistory.b2bStationChargingHistory);
  // const allCountries = useSelector((state) => state.country.countries);
  // const allChargingStations = useSelector((state) => state.chargingStation.chargingStations);
  // const allCity = useSelector((state) => state.city.city);
  // const allState = useSelector((state) => state.state.states);
  // const stateData = _.map(
  //   _.filter(selectedStateOptions, (item) => item.value !== 'All'),
  //   (data) => data.value
  // );
  // const cityData = _.map(
  //   _.filter(selectedCityOptions, (item) => item.value !== 'All'),
  //   (data) => data.value
  // );
  // const stationData = _.map(
  //   _.filter(selectedStationOptions, (item) => item.value !== 'All'),
  //   (data) => data.value
  // );

  const b2bStationChargingHistory = useCallback(
    (value) => {
      const data = {
        ...value,
        type: 'Private',
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        status: 'completed',
      };
      dispatch({ type: B2B_STATION_CHARGING_HISTORY, payload: data });
    },
    [startDate, endDate]
  );

  // const getStateByCountry = useCallback((countryName = '') => {
  //   dispatch({ type: FETCH_STATE, payload: { country_name: countryName } });
  // }, []);

  // const getCityByState = useCallback((stateName = '') => {
  //   dispatch({ type: FETCH_CITY, payload: stateName !== 'All' && { state_name: stateName } });
  // }, []);

  // useEffect(() => {
  //   dispatch({ type: FETCH_COUNTRY, payload: {} });
  //   dispatch({ type: FETCH_CHARGING_STATION, payload: {} });
  // }, []);

  // const stationFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allChargingStations, (station) => {
  //     return { label: station.name, value: station.id };
  //   }),
  // ];

  // const stateFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allState, (state) => {
  //     return { label: state.name, value: state.name };
  //   }),
  // ];

  // const cityFilter = [
  //   { label: 'All', value: 'All' },
  //   ..._.map(allCity, (city) => {
  //     return { label: city.name, value: city.name };
  //   }),
  // ];

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
        to: moment(endDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'),
      };
      b2bStationChargingHistory(filterDateData);
    }
  }, [startDate, endDate, sortByItem]);

  // function getDropdownButtonLabel({ placeholderButtonLabel }) {
  //   return `${placeholderButtonLabel}`;
  // }

  // function stateOnChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //     getCityByState(_.get(event, 'option.value'));
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //  else if (value.length === this.options.length - 1) {
  //   this.setState(this.options);
  //   getCityByState(_.get(event, 'option.value'));
  // }
  //   else {
  //     this.setState(value);
  //     getCityByState(_.get(event, 'option.value'));
  //   }
  // }

  // function onChange(value, event) {
  //   if (event.action === 'select-option' && event.option.value === 'All') {
  //     this.setState(this.options);
  //   } else if (event.action === 'deselect-option' && event.option.value === 'All') {
  //     this.setState([]);
  //   } else if (event.action === 'deselect-option') {
  //     this.setState(value.filter((o) => o.value !== 'All'));
  //   }
  //   // else if (value.length === this.options.length - 1) {
  //   //   this.setState(this.options);
  //   // }
  //   else {
  //     this.setState(value);
  //   }
  // }
  // const filterData = [
  //   { label: 'Newest', value: 'Newest' },
  //   { label: 'Oldest', value: 'Oldest' },
  //   { label: 'All', value: 'All' },
  // ];

  // useEffect(() => {
  //   const newestData = {
  //     sortBy: '1',
  //   };
  //   fieldValue === 'Newest' && b2bStationChargingHistory(newestData);
  //   const oldestData = {
  //     sortBy: '-1',
  //   };
  //   fieldValue === 'Oldest' && b2bStationChargingHistory(oldestData);
  //   fieldValue === 'All' && b2bStationChargingHistory();
  // }, [fieldValue]);

  useEffect(() => {
    setAllB2bStationChargingHistoryData(
      _.filter(
        b2bStationChargingHistoryList,
        (item) =>
          startsWith(_.get(item, 'charger.charging_station.country'), searchText) ||
          startsWith(_.get(item, 'charger.charging_station.state'), searchText) ||
          startsWith(_.get(item, 'charger.charging_station.city'), searchText) ||
          startsWith(_.get(item, 'charger.charger_id'), searchText) ||
          startsWith(_.get(item, 'vehicle.model'), searchText) ||
          startsWith(_.get(item, 'vehicle.vin_num'), searchText) ||
          startsWith(roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3).toString(), searchText) ||
          startsWith(millisecondsToHourMinute(_.get(item, 'time_taken')).toString(), searchText) ||
          startsWith(_.get(item, 'idTag'), searchText) ||
          startsWith(_.get(item, 'StopSoC').toString(), searchText) ||
          startsWith(_.get(item, 'StartSoC').toString(), searchText) ||
          startsWith(_.get(item, 'meterstart').toString(), searchText) ||
          startsWith(_.get(item, 'meterstop').toString(), searchText) ||
          startsWith(_.get(item, 'transaction_id'), searchText)
      )
    );
  }, [searchText, b2bStationChargingHistoryList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  useEffect(() => {
    b2bStationChargingHistory();
  }, []);

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page,
      };
      b2bStationChargingHistory(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      b2bStationChargingHistory(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="B2B Station Charging History" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col
                      xl={`${showSearchBar ? 2 : 1}`}
                      md={`${showSearchBar ? 12 : 2}`}
                      className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                    >
                      {showSearchBar ? search : searchIcon}
                    </Col>
                    {/* <Col xl={2} md={4}>
                      <DropDown
                        popupClass="main-menu bottom scrollable"
                        trigger={
                          <>
                            <div className="log-filter-block">
                              <div className="log-filter-icon">
                                <BsFilter />
                                <span className="log-filter-label">{fieldValue}</span>
                              </div>
                              <div className="log-arrow__icon">
                                <AiFillCaretDown />
                              </div>
                            </div>
                          </>
                        }
                        triggerClass="log-filter-dropdown"
                      >
                        {_.map(filterData, (item, index) => (
                          <ul key={`${index}`}>
                            <li
                              className="item"
                              onClick={() => {
                                setFieldValue(item.value);
                              }}
                            >
                              {item.label}
                            </li>
                          </ul>
                        ))}
                      </DropDown>
                    </Col> */}
                    <Col xl={`${showSearchBar ? 6 : 7}`} md={`${showSearchBar ? 10 : 10}`}>
                      <div className="data-report-date_picker">
                        {/* <DatePickerWithFloatingLabel
                          defaultValueOfStartDate={new Date()}
                          defaultValueOfEndDate={new Date()}
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                        /> */}
                        <DateTimePicker
                          onChangeOfStartDate={(item) => setStartDate(item)}
                          onChangeOfEndDate={(item) => setEndDate(item)}
                          initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').startOf('day')}
                          initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').endOf('day')}
                        />
                      </div>
                    </Col>
                    <Col xl={1} md={2}>
                      <div className="report-search-box" onClick={dateFilter}>
                        <Button className="report-search-button">Search</Button>
                      </div>
                    </Col>
                    <Col xl={2} md={4}>
                      <div className="report-search-excel-block">
                        <div className="advance-filter-btn">
                          <Button className="report-search-button">Advance Filter</Button>
                        </div>
                      </div>
                    </Col>
                    <Col xl={1} md={1} className="excel-icon--block">
                      <div className="report-excel-icon">
                        <BsDownload size={28} color={'#3c7cdd'} />
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xl={2} md={4}>
                      <Select
                        value={countryName}
                        options={_.map(allCountries, (station) => {
                          return { label: station.name, value: station.name };
                        })}
                        placeholder="Select country"
                        onChange={(val) => {
                          setCountryName(val);
                          getStateByCountry(val);
                        }}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={countryName && stateFilter}
                        placeholderButtonLabel="Select state"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStateOptions}
                        onChange={stateOnChange}
                        setState={setSelectedStateOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={cityFilter}
                        placeholderButtonLabel="Select city"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedCityOptions}
                        onChange={onChange}
                        setState={setSelectedCityOptions}
                        hideSearch={true}
                      />
                    </Col>
                    <Col xl={2} md={4}>
                      <ReactMultiSelectCheckboxes
                        options={stationFilter}
                        placeholderButtonLabel="Select station"
                        getDropdownButtonLabel={getDropdownButtonLabel}
                        value={selectedStationOptions}
                        onChange={onChange}
                        setState={setSelectedStationOptions}
                        hideSearch={true}
                      />
                    </Col> */}

                  {/* <Col xl={2} md={4}>
                      <div className="data-report__table-download-icon">
                        <AiOutlineDownload />
                      </div>
                    </Col> */}
                  {/* </Row> */}
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Sr No.</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Country</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>State</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>City</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>OCPP Id</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Connector Type</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Transaction Id</span>
                              <span className="ico" onClick={() => handleSorting('transaction_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charging Date</span>
                              <span className="ico" onClick={() => handleSorting('schedule_datetime ', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Meter Start Reading(Wh)</span>
                              <span className="ico" onClick={() => handleSorting('meterstart', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Meter Stop Reading(Wh)</span>
                              <span className="ico" onClick={() => handleSorting('meterstop', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Session Unit Consumption (kWh)</span>
                              {/* <span className="ico">
                                <TiArrowUnsorted />
                              </span> */}
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Session Start Date Time(hh:mm)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Session Stop Date Time(hh:mm)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Session Duration (hh:mm)</span>
                              <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>StartSoC</span>
                              <span className="ico" onClick={() => handleSorting('StartSoC', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>StopSoC</span>
                              <span className="ico" onClick={() => handleSorting('StopSoC', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Reason to Stop</span>
                              <span className="ico" onClick={() => handleSorting('stop_reason', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>idTag/OTP</span>
                              <span className="ico" onClick={() => handleSorting('idTag', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Vehicle</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>VIN/Registration No.</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_.isEmpty(allB2bStationChargingHistoryData) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">No B2B Station Charging History Found</div>
                            </td>
                          </tr>
                        ) : (
                          _.map(allB2bStationChargingHistoryData, (item, key) => {
                            const connectorData = _.find(_.get(item, 'charger.plugs', []), { connector_id: item.connectorId });
                            const connectorTypeData = _.get(
                              _.find(_.get(item, 'charger.oem.plugs', []), { _id: _.get(connectorData, 'oem_ref', '') }),
                              'connector_type',
                              {}
                            );
                            return (
                              <tr key={`b2b-station-history-${key}`}>
                                <td>{key + 1}</td>
                                <td>{_.get(item, 'charger.charging_station.country', '')}</td>
                                <td>{_.get(item, 'charger.charging_station.state', '')}</td>
                                <td>{_.get(item, 'charger.charging_station.city', '')}</td>
                                <td>{_.get(item, 'charger.charger_id', '')}</td>
                                <td>{_.get(connectorData, 'name', '')}</td>
                                <td>{_.get(connectorTypeData, 'name', '')}</td>
                                <td>{_.get(item, 'transaction_id', '')}</td>
                                <td>{item.schedule_datetime ? moment(item.schedule_datetime).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss') : ''}</td>
                                <td>{_.get(item, 'meterstart', '')}</td>
                                <td>{_.get(item, 'meterstop', '')}</td>
                                <td>
                                  {' '}
                                  {isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                                    ? ''
                                    : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3)}
                                </td>
                                <td>{item.booking_start ? moment(item.booking_start).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss') : ''}</td>
                                <td>{item.booking_stop ? moment(item.booking_stop).tz('Asia/Dubai').format('DD/MM/YYYY H:mm:ss') : ''}</td>
                                <td>{millisecondsToHourMinute(_.get(item, 'time_taken'))}</td>
                                <td>{_.get(item, 'StartSoC', '')}</td>
                                <td>{_.get(item, 'StopSoC', '')}</td>
                                <td>{_.get(item, 'stop_reason', '')}</td>
                                <td>{_.get(item, 'idTag', '')}</td>
                                <td>{_.get(item, 'vehicle.model', '')}</td>
                                <td>{_.get(item, 'vehicle.vin_num', '')}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default B2BStationChargingHistory;
