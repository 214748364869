import {
  STATION_CHARGING_SUMMARY_SUCCESS,
  STATION_CHARGING_SUMMARY_FAIL,
  STATION_CHARGING_SUMMARY,
  STATION_CHARGING_SUMMARY_CHART,
  STATION_CHARGING_SUMMARY_CHART_SUCCESS,
  STATION_CHARGING_SUMMARY_CHART_FAIL,
} from 'actions/dataReport';

const initialState = {
  isLoading: false,
  stationChargingSummaries: [],
  stationChargingSummaryChart: [],
};

const stationChargingSummaryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STATION_CHARGING_SUMMARY:
    case STATION_CHARGING_SUMMARY_CHART: {
      return { ...state, isLoading: true };
    }
    case STATION_CHARGING_SUMMARY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationChargingSummaries: payload,
      };
    }

    case STATION_CHARGING_SUMMARY_CHART_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationChargingSummaryChart: payload,
      };
    }
      
    case STATION_CHARGING_SUMMARY_FAIL:
    case STATION_CHARGING_SUMMARY_CHART_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default stationChargingSummaryReducer;
