import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GET_CHARGER_STATION, GET_CONSUMPTION } from 'actions/chargingStation';
import Select from 'components/inputs/Select';
import { Row, Col, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import Chart from 'chart.js/auto';

const Consumption = () => {
  const dispatch = useDispatch();
  const { stationId } = useParams();
  const [selectedChargerId, setSelectedChargerId] = useState('All');
  const [chartType, setChartType] = useState('weekly');
  const chargerStationList = useSelector((state) => state?.chargerStation?.chargerList);
  const consumptionData = useSelector((state) => state.consumption.consumptions);
  const isLoader = useSelector((state) => state.consumption.isLoading);
  const chartRef = useRef(null);

  const lineColors = [
    '#FF5733',
    '#33FF57',
    '#3357FF',
    '#F333FF',
    '#33FFF3',
    '#FF33A8',
    '#FFC300',
    '#DAF7A6',
    '#581845',
    '#900C3F',
    '#C70039',
    '#AF7AC5',
    '#45B39D',
    '#2980B9',
  ];

  // Map each chargerId to a specific color
  const chargerColorMap = React.useMemo(() => {
    const map = {};
    chargerStationList?.forEach((charger, index) => {
      map[charger.charger_id] = lineColors[index % lineColors.length];
    });
    return map;
  }, [chargerStationList]);

  const getPastDates = (type) => {
    const now = new Date();
    if (type === 'weekly') {
      const dates = [];
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(now.getDate() - i);
        dates.push(date.toLocaleDateString('en-US', { weekday: 'long' }));
      }
      return dates;
    } else {
      const dates = [];
      for (let i = 11; i >= 0; i--) {
        const date = new Date();
        date.setMonth(now.getMonth() - i);
        dates.push(date.toLocaleDateString('en-US', { month: 'long' }));
      }
      return dates;
    }
  };

  const consumption = useCallback(
    (value) => {
      const data = {
        ...value,
        stationID: [stationId],
        charger: value.charger === '' ? undefined : value.charger,
      };
      dispatch({ type: GET_CONSUMPTION, payload: data });
    },
    [consumptionData]
  );

  const getChargerStation = useCallback(
    (value) => {
      const data = {
        ...value,
        limit: 100,
        charging_station: [stationId],
      };
      dispatch({
        type: GET_CHARGER_STATION,
        payload: data,
      });
    },
    [chargerStationList]
  );

  useEffect(() => {
    consumption(stationId);
    getChargerStation({ id: stationId });
  }, [stationId]);

  const handleChargerSelect = (selectedOption) => {
    if (_.includes(selectedOption, 'All')) {
      setSelectedChargerId(selectedOption);
      consumption({ charger: '' });
    } else {
      setSelectedChargerId(_.without(selectedOption, 'All'));
      consumption({ charger: _.without(selectedOption, 'All') });
    }
  };

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }

    const canvas = document.getElementById('lineChart');
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const chartDataKey = chartType === 'weekly' ? 'weeklyUnitConsumption' : 'monthlyUnitConsumption';
    const labels = getPastDates(chartType);

    const datasets =
      selectedChargerId && selectedChargerId.length > 0
        ? Object.keys(consumptionData).map((chargerId) => {
            const chargerConsumption = consumptionData[chargerId]?.[chartDataKey] || {};
            return {
              label: chargerId,
              data: labels.map((label) => chargerConsumption[label]),
              fill: false,
              backgroundColor: chargerColorMap[chargerId],
              borderColor: chargerColorMap[chargerId],
              borderWidth: 2,
              tension: 0.4,
            };
          })
        : [];

    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels,
        datasets,
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: chartType === 'weekly' ? 'Days of the Week' : 'Months of the Year',
              color: '#fff',
            },
            ticks: {
              color: '#fff',
            },
            grid: {
              color: 'rgba(255,255,255,0.1)',
              borderColor: '#fff',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Unit Consumption (kWh)',
              color: '#fff',
            },
            ticks: {
              color: '#fff',
            },
            grid: {
              color: 'rgba(255,255,255,0.1)',
              borderColor: '#fff',
            },
          },
        },
      },
    });
  }, [consumptionData, chartType, selectedChargerId]);

  return (
    <>
      <div>
        <Row className="consumption_div">
          <Col lg={2} md={3} sm={6}>
            <Select
              isMulti
              label={'Select Charger'}
              options={
                selectedChargerId.includes('All')
                  ? [{ label: 'All', value: 'All' }]
                  : [
                      { label: 'All', value: 'All' },
                      ..._.map(chargerStationList, (item) => {
                        return { label: item.charger_id, value: item.id };
                      }),
                    ]
              }
              placeholder={'Select Charger'}
              name="chargerName"
              onChange={handleChargerSelect}
              value={selectedChargerId}
            />
          </Col>
          <Col lg={3} md={3} sm={6}>
            <div className="consumption_btn">
              <div onClick={() => handleChartTypeChange('weekly')} className={chartType === 'weekly' ? 'active' : ''}>
                Weekly
              </div>
              <div onClick={() => handleChartTypeChange('monthly')} className={chartType === 'monthly' ? 'active' : ''}>
                Monthly
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            {isLoader ? (
              <div className="loader--block">
                <Spinner as="span" animation="border" size="sm" role="status" />
              </div>
            ) : (
              <canvas id="lineChart" width="400" height="150" style={{ marginTop: '1rem' }}></canvas>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Consumption;
