import { get, post, deleteRequest, patch } from 'utils/api';

export const getAllChargingStation = (data) => {
  return get(`/chargingStations`, data);
};

export const getChargingStation = (id) => {
  return get(`/chargingStations/${id}`);
};

export const addChargingStation = (data) => {
  return post(`/chargingStations`, data);
};

export const deleteChargingStation = (id) => {
  return deleteRequest(`/chargingStations/${id}`);
};

export const updateChargingStation = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/chargingStations/${id}`, data);
};

export const getStationReview = (data) => {
  return get('/reviewStations', data);
};

export const updateContactPerson = (data) => {
  const id = data.id;
  delete data['id'];
  return patch(`/users/${id}`, data);
};

export const getwidgets = (data) => {
  return post(`/chargingStations/csWidgets`, data);
};

export const consumption = (data) => {
  return post(`/chargingStations/consumption/${data.stationID}`, {
    charger: data.charger ? data.charger : '',
  });
};

export const getChargerStation = (data) => {
  const page = data.page ? data.page : 1;
  const limit = data.limit ? data.limit : 10;
  const id = data.id;
  delete data['page'];
  delete data['limit'];
  return get(`/chargingStations/chargers/${id}?page=${parseInt(page)}&limit=${parseInt(limit)}`, data);
};

export const getStationInfo = (id) => {
  return post(`/chargingStations/chargingStationInfo/${id}`);
};

export const chargingStation = {
  getAllChargingStation,
  getChargingStation,
  addChargingStation,
  deleteChargingStation,
  updateChargingStation,
  getStationReview,
  updateContactPerson,
  getwidgets,
  consumption,
  getChargerStation,
  getStationInfo,
};
